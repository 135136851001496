import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Card,
  Spinner,
} from "react-bootstrap";
import { makeStringNames } from "../Components/MakeStringCollection";
import { classNames } from "../Components/ClassNameCollections";
import NavBar from "../Components/Navbar";
import { Link, useLocation } from "react-router-dom";
import Express_URL from "../Components/Express_URL";
import { Adsense } from "@ctrl/react-adsense";
import { Helmet } from "react-helmet";

const Advertisement1 = () => (
  <Col key="advertisement-1" xs={12} md={6} lg={4}>
    <div
      className="border-0 rounded-2 bg-white "
      style={{
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      }}
    >
      <small
        className="text-start"
        style={{ paddingLeft: "32px", fontSize: "12px" }}
      >
        Advertisement
      </small>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "330px" }}
      >
        <Adsense
          client="ca-pub-6409212221098617"
          slot="1759471789"
          style={{
            width: 300,
            height: 300,
          }}
          format=""
        />
      </div>
    </div>
  </Col>
);

const Advertisement2 = () => (
  <Col key="advertisement-2" xs={12} md={6} lg={4}>
    <div
      className="border-0 rounded-2 bg-white "
      style={{
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      }}
    >
      <small
        className="text-start"
        style={{ paddingLeft: "32px", fontSize: "12px" }}
      >
        Advertisement
      </small>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "330px" }}
      >
        <Adsense
          client="ca-pub-6409212221098617"
          slot="2178655050"
          style={{
            width: 300,
            height: 300,
          }}
          format=""
        />
      </div>
    </div>
  </Col>
);

const Advertisement3 = () => (
  <Col key="advertisement-3" xs={12} md={6} lg={4}>
    <div
      className="border-0 rounded-2 bg-white "
      style={{
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      }}
    >
      <small
        className="text-start"
        style={{ paddingLeft: "32px", fontSize: "12px" }}
      >
        Advertisement
      </small>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "330px" }}
      >
        <Adsense
          client="ca-pub-6409212221098617"
          slot="6437384363"
          style={{
            width: 300,
            height: 300,
          }}
          format=""
        />
      </div>
    </div>
  </Col>
);
function Search() {
  const location = useLocation();
  const initialMake = new URLSearchParams(location.search).get("make") || "All";
  const [yachts, setYachts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [make, setMake] = useState(initialMake);
  const [condition, setCondition] = useState("All");
  const initialClassName =
    new URLSearchParams(location.search).get("className") || "";
  const [className, setClassName] = useState(initialClassName);

  const [minLength, setMinLength] = useState("");
  const [maxLength, setMaxLength] = useState("");

  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const [minYear, setMinYear] = useState("");
  const [maxYear, setMaxYear] = useState("");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = (start, rows) => {
    let conditionParam = condition;
    // If 'All' is selected, include both 'New' and 'Used'
    if (condition === "All") {
      conditionParam = "New,Used";
    }

    let apiUrl = `${Express_URL}/documents/?start=${start}&rows=${rows}`;

    // Append selected parameters to the API URL
    if (make !== "All") {
      apiUrl += `&make=${make}`;
    }

    if (condition !== "All") {
      apiUrl += `&condition=${conditionParam}`;
    }

    if (className !== "All") {
      apiUrl += `&boatClass=${className}`;
    }

    if (minLength && maxLength) {
      apiUrl += `&length=${minLength}:${maxLength}|feet`;
    }

    if (minYear && maxYear) {
      apiUrl += `&year=${minYear}:${maxYear}`;
    }

    if (minPrice && maxPrice) {
      apiUrl += `&price=${minPrice}:${maxPrice}|USD`;
    }

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.data.results.length === 0) {
          setError(true);
          setYachts([]);
        } else {
          const yachtsArray = data.data.results;
          setYachts(yachtsArray);
          setTotalPages(Math.ceil(data.data.numResults / rows));
          setError(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
        setLoading(false);
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchData(0, 24);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData(0, 24);
    setPage(1);
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    const nextPage = page + 1;
    const start = (nextPage - 1) * 24;
    fetchData(start, 24);
    setPage(nextPage);
    handleClick();
  };

  const handlePrevPage = () => {
    const prevPage = page - 1;
    const start = (prevPage - 1) * 24;
    fetchData(start, 24);
    setPage(prevPage);
    handleClick();
  };

  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  // Display advertisement after every 6 yachts
  const adInterval = (() => {
    if (
      window.matchMedia("(min-width: 767px) and (max-width: 950px)").matches
    ) {
      return 7; // Tablet
    } else {
      return 8; // Mobile, Desktop
    }
  })();
  //const adInterval = 8;
  const advertisementCol = (
    <Col key="advertisement" xs={12}>
      <Card
        className="border-0"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        }}
      >
        <Card.Body className="p-2">
          <Card.Text>
            <div className="text-center">
              {/* Mobile: Display Image 1 */}
              <Row className="d-md-none">
                <Col>
                  <img
                    src="https://ayg.s3.us-east-2.amazonaws.com/HCB+(2).gif"
                    alt="Mobile"
                    className="img-fluid"
                  />
                </Col>
              </Row>

              {/* Tablet: Display Image 2 */}
              <Row className="d-none d-md-flex d-lg-none">
                <Col>
                  <img
                    src="https://ayg.s3.us-east-2.amazonaws.com/Explore+Travel.gif"
                    alt="Tablet"
                    className="img-fluid"
                  />
                </Col>
              </Row>

              {/* Desktop: Display Image 3 */}
              <Row className="d-none d-lg-flex">
                <Col>
                  <img
                    src="https://ayg.s3.us-east-2.amazonaws.com/Explore+Travel.gif"
                    alt="Desktop"
                    className="img-fluid"
                  />
                </Col>
              </Row>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <div style={{ backgroundColor: "#f4f4f4" }}>
      <Helmet>
        <meta
          name="title"
          content="Yachts for Sale | Luxury Yachts, Motor Yachts, and Sailing Yachts"
        />
        <meta
          name="description"
          content=" Explore a wide range of luxury yachts for sale, including motor yachts, sailing yachts, and superyachts. Find your perfect yacht today with expert brokerage services at MegaSails.com"
        />
        <meta
          name="keywords"
          content="Yachts for Sale, Luxury Yachts for Sale, New Yachts for Sale, Used Yachts for Sale, Superyachts for Sale, Mega Yachts for Sale, Motor Yachts for Sale, Sailing Yachts for Sale, Catamarans for Sale, Custom Yachts for Sale, Private Yachts for Sale, Luxury Boats for Sale, Sport Yachts for Sale, Yacht Brokerage"
        />
        <meta
          name="keywords"
          content="Yachts for Sale in Jupiter, Yachts for Sale in Naples, Yachts for Sale in Orange Beach, Yachts for Sale in Fort Lauderdale"
        />
        <meta
          name="keywords"
          content="Luxury Yachts in Jupiter, Luxury Yachts in Naples, Luxury Yachts in Orange Beach, Luxury Yachts in Fort Lauderdale"
        />
        <meta
          name="keywords"
          content="Yachts for Sale Jupiter, Yachts for Sale Naples, Yachts for Sale Orange Beach, Yachts for Sale Fort Lauderdale"
        />
        <meta
          name="keywords"
          content="Yacht Brokers in Jupiter, Yacht Brokers in Naples, Yacht Brokers in Orange Beach, Yacht Brokers in Fort Lauderdale"
        />
        <meta
          name="keywords"
          content="Boats for Sale in Jupiter, Boats for Sale in Naples, Boats for Sale in Orange Beach, Boats for Sale in Fort Lauderdale"
        />
        <meta
          name="keywords"
          content="Buy a Yacht, How to Buy a Yacht, Yacht Financing Options, Yacht Ownership, Yacht Buying Guide, Best Yachts to Buy"
        />
        <meta
          name="keywords"
          content="Yacht Charter, Yacht Rentals, Yacht Maintenance, Yacht Management, Pre-Owned Yachts for Sale, Exclusive Yachts for Sale, Yacht Auctions"
        />
        <meta
          name="keywords"
          content="HCB 39 Speciale for Sale, HCB 42 Lujo for Sale, HCB 48 CAMPEON for Sale, HCB 53 Suenos for Sale, HCB 53 SUENOS GIX for Sale, HCB 65 Estrella for Sale"
        />
        <meta
          name="keywords"
          content="Affordable Yachts for Sale, Yachts Under $1 Million, Luxury Yachts Under $10 Million, Best Value Yachts for Sale"
        />
        <meta
          name="keywords"
          content="Luxury Yachts with Flybridge, Yachts with Spacious Cabins, Yachts with Advanced Navigation Systems, Yachts with State-of-the-Art Technology, Yachts with High-Speed Performance, Eco-Friendly Yachts for Sale"
        />
      </Helmet>

      <NavBar />
      <div
        style={{
          background:
            "radial-gradient(ellipse at 50% -50%, #6c94ff 0, #000 100%)",
          height: "75px",
        }}
      ></div>
      <div className="px-2 py-4" style={{ backgroundColor: "#ebf0f7" }}>
        <Container fluid>
          <Form onSubmit={handleSubmit}>
            <Row className="g-1">
              <Col xs={12} md={4} lg={2}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="mb-0">Make</Form.Label>
                  <Form.Control
                    className="rounded-0"
                    as="select"
                    value={make}
                    onChange={(e) => {
                      setMake(e.target.value);
                      setClassName("All");
                    }}
                  >
                    <option value="All">All</option>
                    {makeStringNames.map((make) => (
                      <option key={make} value={make}>
                        {make}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={4} lg={2}>
                <Form.Group>
                  <Form.Label className="mb-0">Condition</Form.Label>
                  <Form.Control
                    className="rounded-0"
                    as="select"
                    value={condition}
                    onChange={(e) => setCondition(e.target.value)}
                  >
                    <option value="New">New</option>
                    <option value="Used">Used</option>
                    <option value="All">All</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={4} lg={2}>
                <Form.Group>
                  <Form.Label className="mb-0">Class Names</Form.Label>
                  <Form.Control
                    className="rounded-0"
                    as="select"
                    value={className}
                    onChange={(e) => {
                      setClassName(e.target.value);
                      setMake("All");
                    }}
                  >
                    <option value="All">All</option>
                    {classNames.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={4} lg={2}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="mb-0">Length</Form.Label>
                  <Row className="g-0">
                    <Col>
                      <Form.Control
                        className="rounded-0"
                        type="text"
                        placeholder="min"
                        value={minLength}
                        onChange={(e) => setMinLength(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        className="rounded-0"
                        type="text"
                        placeholder="max"
                        value={maxLength}
                        onChange={(e) => setMaxLength(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xs={12} md={4} lg={2}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="mb-0">Year</Form.Label>
                  <Row className="g-0">
                    <Col>
                      <Form.Control
                        className="rounded-0"
                        type="text"
                        placeholder="min"
                        value={minYear}
                        onChange={(e) => setMinYear(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        className="rounded-0"
                        type="text"
                        placeholder="max"
                        value={maxYear}
                        onChange={(e) => setMaxYear(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xs={12} md={4} lg={2}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label className="mb-0">Price</Form.Label>
                  <Row className="g-0">
                    <Col>
                      <Form.Control
                        className="rounded-0"
                        type="text"
                        placeholder="min"
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        className="rounded-0"
                        type="text"
                        placeholder="max"
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                className="btn-sm custom-contact-btn p-2 text-white"
                type="submit"
              >
                <i className="fa-solid fa-magnifying-glass"></i> Advanced Search
              </Button>
            </div>
          </Form>
        </Container>
      </div>
      <Container fluid className="col-lg-11">
        <Row className="g-3 mt-4">
          {error && (
            <div className="text-center">
              <h2>No matches found.</h2>
              <p>
                Retry adjusting your search filters or contact us @
                broker@megasails.com
              </p>{" "}
            </div>
          )}
          {loading && (
            <div className="text-center">
              <Spinner animation="border" variant="warning" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          {yachts.map((yacht, index) => (
            <>
              <Col xs={12} md={6} lg={4}>
                <Link to={`/yachtdetails/${yacht.DocumentID}`}>
                  <div
                    className="card border-0"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    }}
                  >
                    <img
                      src={yacht.Images[0].Uri}
                      className="card-img-top"
                      alt="..."
                      loading="lazy"
                      height={"260px"}
                    />
                    <div className="card-body p-2">
                      <div className="text-start">
                        <h5 className="fw-bold mb-1">
                          <div>
                            {truncateText(
                              `${yacht.MakeString} ${yacht.Model}`,
                              25
                            )}
                          </div>
                        </h5>
                      </div>
                      <p className="text-dark mb-0 ">
                        {/* <i className="fa-solid fa-location-dot text-secondary"></i>{" "} */}
                        {truncateText(
                          `${yacht.ModelYear} | ${yacht.BoatLocation.BoatCityName}, ${yacht.BoatLocation.BoatStateCode}, ${yacht.BoatLocation.BoatCountryID}`,
                          30
                        )}
                      </p>
                      <div className="d-flex justify-content-between">
                        <div>
                          <p className="mb-0 fw-bold">
                            {yacht.Price
                              ? `${yacht.Price}`
                              : "$ Call For Price"}
                          </p>
                        </div>
                        <div className="text-dark fw-bold">
                          View Details{"  "}
                          <i className="fa-solid fa-angles-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
              {/* First advertisement after every 9 yachts */}
              {(index + 1) % adInterval === 0 &&
                index !== yachts.length - 1 && (
                  <React.Fragment key={`advertisement-${index}`}>
                    {advertisementCol}
                  </React.Fragment>
                )}
              {index === 2 && <Advertisement1 />}
              {index === 10 && <Advertisement2 />}
              {index === 18 && <Advertisement3 />}
            </>
          ))}
        </Row>

        <div className="d-flex justify-content-center py-4">
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              type="button"
              className="btn custom-contact-btn fw-bold p-2 fs-6"
              onClick={handlePrevPage}
              disabled={page === 1}
            >
              <i className="fa-solid fa-angles-left"></i> Prev
            </button>
            <button type="button" className="btn custom-contact-btn  p-2 fs-6">
              Page {page} of {totalPages}
            </button>
            <button
              type="button"
              className="btn custom-contact-btn fw-bold p-2 fs-6"
              onClick={handleNextPage}
              disabled={page === totalPages}
            >
              Next <i className="fa-solid fa-angles-right "></i>
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Search;
