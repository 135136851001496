import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import Search from "./Pages/Search";
import YachtDetails from "./Pages/yachtDetails";
import Services from "./Pages/Services";
import Contact from "./Pages/ContactUs";
import HCB from "./Pages/HCB";
import Speciale from "./Pages/Speciale";
import Lujo from "./Pages/Lujo";
import Campeon from "./Pages/Campeon";
import Suenos from "./Pages/Suenos";
import Estrella from "./Pages/Estrella";
import IKON from "./Pages/IKON";
import NewsEvents from "./Pages/NewsEvents";
import Footer from "./Components/Footer";
import { useEffect } from "react";
import NewsDetails from "./Pages/newsDetails";
import NewsDetails1 from "./Pages/newsDetails1";
import MarketingPolicy from "./Pages/MarketingPolicy";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
//import Advertisement from "./Pages/Advertisement";
import NewsDetails2 from "./Pages/newsDetails2";
import NewsDetails3 from "./Components/newsDetails3";
import NewsDetails4 from "./Pages/newsDetails4";
import Awake from "./Pages/Awake";
import JetBoards from "./Pages/JetBoards";
import Efoils from "./Pages/Efoils";
import VerifyEmailPage from "./Components/VerifyEmailPage";
import Cookie from "./Components/Cookie";
import MantaRacks from "./Pages/MantaRacks";

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      // Scroll to the top of the page whenever the route changes
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route index element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/yachts-for-sale" element={<Search />} />
          <Route path="/yachtdetails/:id" element={<YachtDetails />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/hcb" element={<HCB />} />
          <Route path="/39-speciale" element={<Speciale />} />
          <Route path="/42-lujo" element={<Lujo />} />
          <Route path="/48-campeon" element={<Campeon />} />
          <Route path="/53-suenos" element={<Suenos />} />
          <Route path="/65-estrella" element={<Estrella />} />
          <Route path="/ikon" element={<IKON />} />
          <Route path="/news-events" element={<NewsEvents />} />
          <Route
            path="/news-details/preparing-for-hurricane"
            element={<NewsDetails />}
          />
          <Route
            path="/news-details/39-turns-head"
            element={<NewsDetails1 />}
          />
          <Route
            path="/news-details/campeon-innovation-award"
            element={<NewsDetails2 />}
          />
          <Route
            path="/news-details/opening-of-new-office"
            element={<NewsDetails3 />}
          />
          <Route
            path="/news-details/opening-of-new-office"
            element={<NewsDetails4 />}
          />
          <Route path="/awake" element={<Awake />} />
          <Route path="/awake-jetboards" element={<JetBoards />} />
          <Route path="/awake-efoils" element={<Efoils />} />

          <Route path="/mantaracks" element={<MantaRacks />} />

          <Route path="/marketing-policy" element={<MarketingPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="/ads" element={<Advertisement />} /> */}
          <Route path="/verify-email/:token" element={<VerifyEmailPage />} />
        </Routes>
        <Cookie />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
