import React from "react";
import Navbar from "../Components/Navbar";
import HomeHCB from "../Components/HomeHCB";
//import HomeIKON from "../Components/HomeIKON";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Popup from "../Components/PopUP";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div style={{ backgroundColor: "#f4f4f4" }}>
      <Helmet>
        <meta
          name="title"
          content="Luxury Yachts for Sale | MegaSails - Exclusive Listings"
        />
        <meta
          name="description"
          content="Discover the finest selection of luxury yachts for sale at MegaSails. Browse exclusive listings, featuring high-performance boats located in Fort Lauderdale, Florida. Find your dream yacht today!"
        />
        <meta
          name="keywords"
          content="Yachts for Sale, Luxury Yachts, High-Performance Boats, Mega Yachts, Motor Yachts, Sailing Yachts, Yacht Listings, buy a Yacht New Yachts, Used Yachts, Exclusive Yachts, Yacht Brokers, Yacht Dealership"
        />
        <meta
          name="keywords"
          content="Fort Lauderdale Yachts for Sale, Florida Yachts, High-End Yachts, Premium Yachts, Yacht Marketplace, Yacht Inventory, Yacht Prices, Yacht Specifications, Yacht Features, Yacht Reviews, Yacht Photos, Yacht Details, Yacht Showroom"
        />
        <meta
          name="keywords"
          content="HCB Yachts for Sale, HCB 39 Speciale, HCB 53 Sueños, HCB 48' CAMPEON, MegaSails Yachts, American Yacht Group, MegaSails Fort Lauderdale"
        />
        <meta
          name="keywords"
          content="Best Yachts, Top Yachts, Yacht Financing, Yacht Maintenance, Yacht Charters, Yacht Rentals, Yacht Clubs, Yacht Sales Events, Yacht Discounts, Yacht Upgrades, Yacht Accessories"
        />
      </Helmet>
      <Navbar />
      <Popup />
      <div>
        {/* HCB Content  */}
        <HomeHCB />
        {/* IKON Content  */}
        {/* <HomeIKON /> */}
      </div>

      <div className="container-fluid col-lg-11 pt-5 text-center">
        <header>
          <h3 className="text-dark fw-bold ">
            Welcome to Megasails - Premier Yacht Broker
          </h3>
        </header>
        <main>
          <section>
            <h6 style={{ textAlign: "justify" }}>
              At Megasails, we set the standard in maritime excellence,
              redefining yachting across South Florida, including Naples, Miami,
              Fort Lauderdale, Palm Beach, Stuart, the Bahamas, and beyond. As a
              leading yacht brokerage, we specialize in providing smooth and
              seamless experiences for buying and selling boats, motor yachts,
              cruising vessels, and center-consoles. Our comprehensive services
              include expert yacht{" "}
              <a href="/services" target="_blank" rel="noopener noreferrer">
                Brokerage
              </a>
              , tailored{" "}
              <a href="/services" target="_blank" rel="noopener noreferrer">
                Financing
              </a>{" "}
              solutions, comprehensive{" "}
              <a href="/services" target="_blank" rel="noopener noreferrer">
                Insurance
              </a>{" "}
              options, secure yacht{" "}
              <a href="/services" target="_blank" rel="noopener noreferrer">
                Storage
              </a>{" "}
              facilities, reliable yacht{" "}
              <a href="/services" target="_blank" rel="noopener noreferrer">
                Transportation
              </a>{" "}
              , luxury yacht{" "}
              <a href="/services" target="_blank" rel="noopener noreferrer">
                Charters
              </a>{" "}
              , and hassle-free{" "}
              <a href="/services" target="_blank" rel="noopener noreferrer">
                Trade-In
              </a>{" "}
              options for upgrading your yacht.
            </h6>
          </section>
          <section>
            <h6 style={{ textAlign: "justify" }}>
              In honor of the original Hydra-Sports bass boats that have graced
              the waters for nearly half a century,{" "}
              <a href="/hcb" target="_blank" rel="noopener noreferrer">
                HCB
              </a>{" "}
              Yachts proudly presents the new{" "}
              <a href="/ikon" target="_blank" rel="noopener noreferrer">
                IKON{" "}
              </a>
              boat brand. Explore the latest innovations with the iKon{" "}
              <a href="/ikon" target="_blank" rel="noopener noreferrer">
                LX21
              </a>{" "}
              and{" "}
              <a href="/ikon" target="_blank" rel="noopener noreferrer">
                LX20
              </a>{" "}
              editions, embodying cutting-edge design and performance.
              Additionally, experience the thrill of{" "}
              <a href="/awake" target="_blank" rel="noopener noreferrer">
                Awake
              </a>{" "}
              Electric Surfboards, pushing the boundaries of water sports with
              advanced electric propulsion technology.
            </h6>
          </section>
        </main>
      </div>

      <section className="container-fluid col-lg-11 py-5">
        <div className="col-lg-12 hr-line">
          <div className="d-flex justify-content-start">
            <h5 className="text-dark fw-bold ">Our Yacht Services</h5>
          </div>
        </div>
        <div className="row mt-3 g-2">
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="border rounded-3 p-3">
              <h5 className="text-primary-emphasis fw-bold">
                <i className="fas fa-handshake"></i> Brokerage
              </h5>
              <p className="mb-1">
                With Megasails: Experience Your premier yacht brokerage for
                seamless buying and selling.
              </p>
              <button type="button" className="btn custom-contact-btn btn-sm">
                <Link to={`/services`} className=" text-white fw-bold">
                  View More{"  "}
                  <i className="fa-solid fa-angles-right"></i>
                </Link>
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="border rounded-3 p-3">
              <h5 className="text-primary-emphasis fw-bold">
                <i className="fas fa-money-bill"></i> Financing
              </h5>
              <p className="mb-1">
                Megasails secures flexible yacht financing through premier
                financial partnerships for you.
              </p>
              <button type="button" className="btn custom-contact-btn btn-sm">
                <Link to={`/services`} className=" text-white fw-bold">
                  View More{"  "}
                  <i className="fa-solid fa-angles-right"></i>
                </Link>
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="border rounded-3 p-3">
              <h5 className="text-primary-emphasis fw-bold">
                <i className="fas fa-shield-alt"></i> Insurance
              </h5>
              <p className="mb-1">
                Insure your yacht seamlessly with tailored marine insurance from
                Megasails Broker service.
              </p>
              <button type="button" className="btn custom-contact-btn btn-sm">
                <Link to={`/services`} className=" text-white fw-bold">
                  View More{"  "}
                  <i className="fa-solid fa-angles-right"></i>
                </Link>
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="border rounded-3 p-3">
              <h5 className="text-primary-emphasis fw-bold">
                <i className="fas fa-truck"></i> Transportation
              </h5>
              <p className="mb-1">
                We offers expert Yacht Transportation: land or water, your
                choice.
              </p>
              <button type="button" className="btn custom-contact-btn btn-sm">
                <Link to={`/services`} className=" text-white fw-bold">
                  View More{"  "}
                  <i className="fa-solid fa-angles-right"></i>
                </Link>
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="border rounded-3 p-3">
              <h5 className="text-primary-emphasis fw-bold">
                <i className="fas fa-wind"></i> Hurricane Storage
              </h5>
              <p className="mb-1">
                Secure yacht hurricane storage available at Megasails. Reserve
                your space!
              </p>
              <button type="button" className="btn custom-contact-btn btn-sm">
                <Link to={`/services`} className=" text-white fw-bold">
                  View More{"  "}
                  <i className="fa-solid fa-angles-right"></i>
                </Link>
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="border rounded-3 p-3">
              <h5 className="text-primary-emphasis fw-bold">
                <i className="fas fa-exchange-alt"></i> Trade-In
              </h5>
              <p className="mb-1">
                We Offer effortless yacht upgrade: trade-in your boat for a
                better vessel.
              </p>
              <button type="button" className="btn custom-contact-btn btn-sm">
                <Link to={`/services#trade-in`} className=" text-white fw-bold">
                  View More{"  "}
                  <i className="fa-solid fa-angles-right"></i>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Container fluid className="col-lg-11 py-4">
          <Row className="g-0">
            <Col xs={12} md={4}>
              <div className="effect-1">
                <div className="effect-img">
                  <img
                    src="https://ikonboats.com/wp-content/uploads/2023/03/iKon-X-Hydra-sports-V2-scaled.jpg"
                    alt="group"
                    height={"260px"}
                  />
                </div>
                <div className="effect-text">
                  <Link to={`/ikon`} className="text-white fs-5">
                    <h2>FISHING BOATS</h2>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="effect-1">
                <div className="effect-img">
                  <img
                    src="https://images.boatsgroup.com/images/1/75/56/8617556_20231114143456262_1_XLARGE.jpg"
                    alt="group"
                    height={"260px"}
                  />
                </div>
                <div className="effect-text">
                  <Link
                    to={`/yachts-for-sale?className=Catamaran`}
                    className="text-white fs-5"
                  >
                    <h2>CATAMARANS</h2>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="effect-1">
                <div className="effect-img">
                  <img
                    src="https://images.boatsgroup.com/images/1/4/52/9090452_20231116171616857_1_XLARGE.jpg"
                    alt="group"
                    height={"260px"}
                  />
                </div>
                <div className="effect-text">
                  <Link
                    to={`/yachts-for-sale?make=HCB`}
                    className="text-white fs-5"
                  >
                    <h2>HCB YACHTS</h2>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="effect-1">
                <div className="effect-img">
                  <img
                    src="https://images.boatsgroup.com/images/1/40/72/9134072_20231130084733155_1_XLARGE.jpg"
                    alt="group"
                    height={"260px"}
                  />
                </div>
                <div className="effect-text">
                  <Link
                    to={`/yachts-for-sale?className=Center Consoles`}
                    className="text-white fs-5"
                  >
                    <h2>CENTER CONSOLE</h2>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="effect-1">
                <div className="effect-img">
                  <img
                    src="https://images.boatsgroup.com/images/1/81/99/9018199_950373073_0_280820230947_0.jpg"
                    alt="group"
                    height={"260px"}
                  />
                </div>
                <div className="effect-text">
                  <Link
                    to={`/yachts-for-sale?className=Motor Yachts`}
                    className="text-white fs-5"
                  >
                    <h2>MOTOR YACHTS</h2>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="effect-1">
                <div className="effect-img">
                  <img
                    src="https://images.boatsgroup.com/images/1/7/33/8660733_20230120054239615_1_XLARGE.jpg"
                    alt="group"
                    height={"260px"}
                  />
                </div>
                <div className="effect-text">
                  <Link
                    to={`/yachts-for-sale?make=Sailfish`}
                    className="text-white fs-5"
                  >
                    <h2>SAILFISH</h2>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Link to={`/yachts-for-sale?make=HCB`}>
          <Container fluid className="col-lg-11 py-3">
            <Col key="advertisement" xs={12}>
              <Card
                className="border-0"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                }}
              >
                <Card.Body className="p-2">
                  <Card.Text>
                    <div className="text-center">
                      {/* Mobile: Display Image 1 */}
                      <Row className="d-md-none">
                        <Col>
                          <img
                            src="https://ayg.s3.us-east-2.amazonaws.com/HCB+(2).gif"
                            alt="Mobile"
                            className="img-fluid"
                          />
                        </Col>
                      </Row>

                      {/* Tablet: Display Image 2 */}
                      <Row className="d-none d-md-flex d-lg-none">
                        <Col>
                          <img
                            src="https://ayg.s3.us-east-2.amazonaws.com/Explore+Travel.gif"
                            alt="Tablet"
                            className="img-fluid"
                          />
                        </Col>
                      </Row>

                      {/* Desktop: Display Image 3 */}
                      <Row className="d-none d-lg-flex">
                        <Col>
                          <img
                            src="https://ayg.s3.us-east-2.amazonaws.com/Explore+Travel.gif"
                            alt="Desktop"
                            className="img-fluid"
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Container>
        </Link>
      </section>
    </div>
  );
};

export default Home;
